<template>
  <div class="relative">
    <div
      @click="toggleFooter"
      class="absolute bottom-full right-[20%] transform translate-x-1/2 bg-background border-t border-x border-border rounded-t-md px-4 py-2 cursor-pointer hover:bg-accent transition-colors duration-200"
    >
      <ChevronUpIcon v-if="isFooterVisible" class="h-5 w-5" />
      <ChevronDownIcon v-else class="h-5 w-5" />
    </div>
    <footer
      class="bg-background border-t border-border fixed bottom-0 left-0 right-0 transition-transform duration-300 ease-in-out"
      :class="{ 'translate-y-full': !isFooterVisible }"
    >
      <div class="container mx-auto px-4 h-16 flex justify-between items-center max-w-7xl">
        <p class="text-sm text-muted-foreground">&copy; 2024 MemeStore Technologies. All rights reserved.</p>
        <nav>
          <ul class="flex space-x-4">
            <li>
              <a
                href="/privacy-policy"
                rel="noopener noreferrer"
                class="text-sm text-muted-foreground hover:text-primary"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="/terms-of-service"
                rel="noopener noreferrer"
                class="text-sm text-muted-foreground hover:text-primary"
              >
                Terms of Service
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { ChevronUpIcon, ChevronDownIcon } from 'lucide-vue-next';

const isFooterVisible = ref(true); // Set to true by default to make the footer visible

const toggleFooter = () => {
  isFooterVisible.value = !isFooterVisible.value;
};
</script>

<style scoped>
.rounded-t-md {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
</style>
