import { createClient } from '@supabase/supabase-js'

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json, text/plain, */*'
  },
  persistSession: true,
  autoRefreshToken: true,
})

export async function setupSupabaseStorage() { 
  const bucketName = 'memes'
    console.log(bucketName + ' are fun')
} 

const imageCache = new Map()

export async function getSupabaseImageUrl(userId, memeId, fileExt) {
  const path = `${userId}/${memeId}.${fileExt}`
  const cacheKey = `${path}_${Date.now()}`
  
  if (imageCache.has(cacheKey)) {
    return imageCache.get(cacheKey)
  }

  const { data } = supabase.storage.from('memes').getPublicUrl(path)
  const url = data.publicUrl

  // Cache the URL for 5 minutes
  imageCache.set(cacheKey, url)
  setTimeout(() => imageCache.delete(cacheKey), 5 * 60 * 1000)

  return url
}
