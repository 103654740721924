<template>
  <div class="relative w-full max-w-4xl">
    <Input
      v-model="localSearchQuery"
      type="text"
      placeholder="Search memes..."
      class="pr-10 w-full"
    />
    <Button
      @click="clearSearch"
      variant="ghost"
      size="icon"
      class="absolute right-8 top-0 h-full"
      v-if="localSearchQuery"
    >
      <XIcon class="h-4 w-4" />
    </Button>
    <Button
      variant="ghost"
      size="icon"
      class="absolute right-0 top-0 h-full"
    >
      <SearchIcon class="h-4 w-4" />
    </Button>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import { SearchIcon, XIcon } from 'lucide-vue-next'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { useSearchStore } from '@/store/search'
import { useDebounceFn } from '@vueuse/core'

const searchStore = useSearchStore()
const localSearchQuery = ref('')

const updateSearchQuery = useDebounceFn((value) => {
  console.log('updateSearchQuery called with:', value)
  searchStore.setSearchQuery(value)
}, 300)

watch(localSearchQuery, (newValue) => {
  console.log('localSearchQuery changed to:', newValue)
  updateSearchQuery(newValue)
})

watch(() => searchStore.searchQuery, (newValue) => {
  console.log('searchStore.searchQuery changed to:', newValue)
  localSearchQuery.value = newValue
})

const clearSearch = () => {
  console.log('clearSearch called')
  localSearchQuery.value = ''
  searchStore.clearSearchQuery()
}

onMounted(() => {
  console.log('SearchBar mounted, initial searchQuery:', searchStore.searchQuery)
})
</script>

