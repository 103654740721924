import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useSearchStore = defineStore('search', () => {
  const searchQuery = ref('')

  const setSearchQuery = (query) => {
    console.log('setSearchQuery called with:', query)
    searchQuery.value = query
  }

  const clearSearchQuery = () => {
    console.log('clearSearchQuery called')
    searchQuery.value = ''
  }

  const debugSearchQuery = computed(() => {
    console.log('debugSearchQuery accessed, value:', searchQuery.value)
    return searchQuery.value
  })

  return {
    searchQuery: debugSearchQuery,
    setSearchQuery,
    clearSearchQuery
  }
})
