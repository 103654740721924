import { ref, watch, onMounted } from 'vue'

const isDarkMode = ref(false)

export function useTheme() {
  const toggleDarkMode = () => {
    isDarkMode.value = !isDarkMode.value
    updateDarkMode()
    savePreference()
  }

  const updateDarkMode = () => {
    document.documentElement.classList.toggle('dark', isDarkMode.value)
  }

  const savePreference = () => {
    localStorage.setItem('darkMode', isDarkMode.value)
  }

  const loadPreference = () => {
    const savedPreference = localStorage.getItem('darkMode')
    if (savedPreference !== null) {
      isDarkMode.value = savedPreference === 'true'
    } else {
      isDarkMode.value = window.matchMedia('(prefers-color-scheme: dark)').matches
    }
    updateDarkMode()
  }

  onMounted(() => {
    loadPreference()
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
      if (localStorage.getItem('darkMode') === null) {
        isDarkMode.value = e.matches
        updateDarkMode()
      }
    })
  })

  watch(isDarkMode, updateDarkMode)

  return {
    isDarkMode,
    toggleDarkMode
  }
}
