import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import './styles/main.css'
import { setupSupabaseStorage } from '@/services/supabase'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { supabase } from '@/services/supabase'
import { useUserStore } from '@/store/user'
import { useSearchStore } from '@/store/search'

const pinia = createPinia()

async function initializeApp() {
  try {
    await setupSupabaseStorage()
  } catch (error) {
    console.error('Failed to setup Supabase storage:', error)
  }

  const app = createApp(App)

  app.use(router)
  app.use(pinia)
  app.use(Toast, {
    position: 'bottom-right',
    timeout: 3000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: false,
    icon: false,
    rtl: false
  })

  supabase.auth.onAuthStateChange((event) => {
    const userStore = useUserStore()
    if (event === 'SIGNED_IN') {
      userStore.fetchUser()
    }
  })

  router.beforeEach((to, from, next) => {
    const searchStore = useSearchStore()
    searchStore.clearSearchQuery()
    next()
  })

  app.mount('#app')
}

initializeApp()
