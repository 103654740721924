<template>
  <header class="bg-background border-b border-border font-mono sticky top-0 left-0 right-0 z-50">
    <div class="container mx-auto px-4 py-4 flex items-center justify-between h-16 max-w-7xl">
      <!-- Logo and Navigation section -->
      <div class="flex items-center space-x-4">
        <router-link to="/" class="flex-shrink-0">
          <img
            :src="isDarkMode ? logoLight : logoDark"
            alt="MemeStore Logo"
            class="h-8 w-8"
          />
        </router-link>
        <nav v-if="isLoggedIn" class="hidden md:flex">
          <ul class="flex space-x-2">
            <li v-for="item in navItems" :key="item.path">
              <router-link
                :to="item.path"
                class="text-xs uppercase tracking-wide pixel-box"
              >
                {{ item.name }}
              </router-link>
            </li>
          </ul>
        </nav>
      </div>

      <!-- User actions section -->
      <div class="flex items-center space-x-4">
        <!-- Search section -->
        <div class="w-64">
          <SearchBar v-if="isLoggedIn"/>
          <div v-else class="h-10"></div>
        </div>

        <!-- User actions section with fixed width -->
        <div class="flex items-center space-x-2">
          <div class="relative w-[150px]">
            <DropdownMenu v-if="isLoggedIn">
              <DropdownMenuTrigger as="div" class="inline-flex">
                <Button variant="ghost" class="inline-flex items-center space-x-1 px-3">
                  <UserIcon class="h-5 w-5" />
                  <span class="max-w-[80px] truncate hidden sm:inline">{{ username }}</span>
                  <ChevronDownIcon class="h-4 w-4 ml-1" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent 
                class="min-w-[8rem] absolute right-0"
                :align="'end'"
                :alignOffset="-5"
                :sideOffset="5"
              >
                <DropdownMenuItem as="div">
                  <router-link to="/profile" class="w-full">Profile</router-link>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem as="div" @select="router.push('/manage')">
                  <span>Manage Memes</span>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem as="div" @click="logout">
                  Logout
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <Button v-else @click="$router.push('/login')" variant="ghost" class="w-full">
              Login
            </Button>
          </div>
          <!-- Theme toggle -->
          <Button variant="ghost" size="icon" @click="toggleDarkMode">
            <SunIcon v-if="isDarkMode" class="h-5 w-5" />
            <MoonIcon v-else class="h-5 w-5" />
          </Button>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { computed, onMounted, watch } from 'vue'
import SearchBar from '@/components/search/SearchBar.vue'
import { SunIcon, MoonIcon, UserIcon, ChevronDownIcon } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator
} from '@/components/ui/dropdown-menu'
import { useUserStore } from '@/store/user'
import { useRouter } from 'vue-router'
import { useTheme } from '@/composables/useTheme'
import { useRoute } from 'vue-router'
import { useSearchStore } from '@/store/search'

const userStore = useUserStore()
const router = useRouter()
const logoLight = new URL('@/assets/logo-light.png', import.meta.url).href
const logoDark = new URL('@/assets/logo-dark.png', import.meta.url).href

const { isDarkMode, toggleDarkMode } = useTheme()

const navItems = [
  { name: 'Home', path: '/' },
  { name: 'Favorites', path: '/favorites' },
  { name: 'Upload', path: '/upload' },
  { name: 'MemeWorld', path: '/global' },
]

const logout = async () => {
  await userStore.logout()
  router.push('/login')
}

const isLoggedIn = computed(() => userStore.isLoggedIn)
const username = computed(() => userStore.currentUser?.username || 'User')
const route = useRoute()
const searchStore = useSearchStore()

onMounted(async () => {
  if (userStore.isLoggedIn) {
    await userStore.fetchUserProfile()
  }
})

watch(() => userStore.isLoggedIn, async (newValue) => {
  if (newValue) {
    await userStore.fetchUserProfile()
  }
})

watch(route, (newRoute) => {
  console.log('Route changed to:', newRoute.path)
  searchStore.clearSearchQuery()
})
</script>

<style scoped>
.pixel-box {
  padding: 0.25rem 0.5rem;
  border: 1px solid var(--color-primary);
  border-radius: 0.25rem;
}
</style>
