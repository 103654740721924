import { defineStore } from 'pinia'
import { supabase } from '@/services/supabase'
import { isValidInviteCode } from '@/config/inviteCode'
import { useToast } from 'vue-toastification'


export const useUserStore = defineStore('user', {
  state: () => ({
    currentUser: null,
    isInitialized: false
  }),
  actions: {
    async login(email, password) {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password
      })

      if (error) {
        console.error('Error logging in:', error)
        throw error
      }

      this.currentUser = data.user
      await this.fetchUserProfile()
      return true
    },
    async signUp(email, password, inviteCode) {
      const toast = useToast()

      if (!isValidInviteCode(inviteCode)) {
        throw new Error('Invalid invite code. Please check and try again.')
      }

      const { data, error } = await supabase.auth.signUp({
        email,
        password
      })

      if (error) {
        console.error('Error signing up:', error)
        throw error
      }

      toast.success("Verify your email to start memeing.", {
        icon: "✉️",
        timeout: 5000
      })

      return true
    },
    async fetchUser() {
      try {
        const { data: { user } } = await supabase.auth.getUser()
        this.currentUser = user
        this.isInitialized = true
        if (user) {
          await this.fetchUserProfile()
        }
      } catch (error) {
        console.error('Error fetching user:', error)
        this.currentUser = null
        this.isInitialized = true
      }
    },
    async fetchUserProfile() {
      if (!this.currentUser) return

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', this.currentUser.id)
        .single()

      if (error) {
        console.error('Error fetching user profile:', error)
      } else {
        this.currentUser = { ...this.currentUser, ...data }
      }
    },
    async updateUserProfile(updates) {
      if (!this.currentUser) return
    
      const { data, error } = await supabase
        .from('users')
        .update(updates)
        .eq('id', this.currentUser.id)
        .select()
    
      if (error) {
        console.error('Error updating user profile:', error)
        throw error
      } else {
        this.currentUser = { ...this.currentUser, ...data[0] }
        // Force reactivity
        this.currentUser = { ...this.currentUser }
      }
    },

    async logout() {
      const { error } = await supabase.auth.signOut()
      if (error) {
        console.error('Error logging out:', error)
        throw error
      } else {
        this.currentUser = null
      }
    },

    async updateEmail(newEmail) {
      const { error } = await supabase.auth.updateUser({ email: newEmail })
      if (error) {
        console.error('Error updating email:', error)
        toast.error('Failed to update email. Please try again.')
        throw error
      } else {
        toast.success('Email update request sent. Please check your old email for confirmation.')
      }
    },
    
  },

  getters: {
    isLoggedIn: (state) => !!state.currentUser
  }
})
